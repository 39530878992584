import React, {useEffect, useState} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import GraphImg2 from "../../assests/images/graph2.png";
import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import MarketingAnalytics from "../../components/modules//marketing_analytics"

const PresentationPage = () => {

    const [ playVideo, setPlayVideo ] = useState(false);
    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('marketing-analytics');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    //console.log("article_data", article_data);

    return <>
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
    <div className="pre-wrapper">

     <Header />

     <MarketingAnalytics />


      <NavLink tab_name="Marketing" prevLink={config.custom_launch}  nextLink={config.custom_prop_datetime} />
    </div>
    </>
}

export default PresentationPage